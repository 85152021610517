.subscription-table {
    display: block;
    overflow-x: auto;
		padding: 0;
		margin-top: 10px;
		border: 1px solid #ddd;
}
.row-title {
		font-size: 1.1rem;
		background-color: #2BBBAD;
}
.with-border {
	border: solid 1px #ccc;
}
.json-to-table table tbody tr td table {
    display: block;
    overflow-x: auto;
}
.json-to-table table {
    width: 100%;
    border-collapse: collapse;
    font-family: sans-serif;
    font-size: 0.8rem;
    display: block;
    overflow-x: auto;
}
table {
    border-spacing: unset !important;
}
thead {
    background-color: #00897b;
    color: white;
}
tbody {
    background-color: #e0f2f1;
    color: black;
}
.isa_info, .isa_success, .isa_warning, .isa_error {
    margin: 10px 0px;
    padding:12px;
     
    }
    .isa_info {
        color: #00529B;
        background-color: #BDE5F8;
    }
    .isa_success {
        color: #4F8A10;
        background-color: #DFF2BF;
    }
    .isa_warning {
        color: #9F6000;
        background-color: #FEEFB3;
    }
    .isa_error {
        color: #D8000C;
        background-color: #FFD2D2;
    }
    .isa_info i, .isa_success i, .isa_warning i, .isa_error i {
        margin:10px 22px;
        font-size:2em;
        vertical-align:middle;
    }
    .icon_message_error {
        font-size: 50px;
    }
.row-result {
  background-color: #26a699 !important;
}
.rms-text {
  height: 1rem !important;
}
.rms-text span {
  border-bottom: 1px solid rgb(158, 158, 158) !important;
  padding-top: 2.5rem !important;
}
.rms-caret {
  color: rgb(158, 158, 158)!important;
}
.rms-wrapper {
  margin-left: 3rem !important;
}
.to-do-list-container {
  margin-top: 25px;
}

.to-do-list-item {
  margin-top: 10px;
  color: white;
  transition: background-color 1s;
}

.to-do-list-item h4 {
  padding-left: 25px;
  padding-bottom: 5px;
}

.to-do-list-item:hover {
  background-color: #26a69a !important;
}

.complete-todo-item {
  float: right;
  right: 20px;
}

#todo-add-form {
  animation-duration: 1s;
  animation-name: slidein;
}

#nothing-was-found {
  margin-top: 150px;
}

#sign-out-button {
  margin-right: 15px;
}

@keyframes slidein {
  from {
    opacity: 0;
  }

  to {
    margin-left: 1;
  }
}

.modal-styles {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in;
  background: rgba(0, 0, 0, 0.5);
	padding: 0 140px;
}

.center {
  align-items: center;
}

.start {
  align-items: start;
}